import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Close = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect className={sharedStyles.fill} x="11" y="11" width="2" height="2" />
      <rect className={sharedStyles.fill} x="13" y="13" width="2" height="2" />
      <rect className={sharedStyles.fill} x="9" y="9" width="2" height="2" />
      <rect className={sharedStyles.fill} x="7" y="7" width="2" height="2" />
      <rect className={sharedStyles.fill} x="5" y="5" width="2" height="2" />
      <rect className={sharedStyles.fill} x="9" y="5" width="2" height="2" />
      <rect className={sharedStyles.fill} x="5" y="9" width="2" height="2" />
      <rect className={sharedStyles.fill} x="3" y="11" width="2" height="2" />
      <rect className={sharedStyles.fill} x="1" y="13" width="2" height="2" />
      <rect className={sharedStyles.fill} x="11" y="3" width="2" height="2" />
      <rect className={sharedStyles.fill} x="13" y="1" width="2" height="2" />
      <rect className={sharedStyles.fill} x="3" y="3" width="2" height="2" />
      <rect className={sharedStyles.fill} x="1" y="1" width="2" height="2" />
    </svg>
  </div>
);

export default Close;
