import PropTypes from 'prop-types';
import '@src/style/index.scss';

const wrapRootElement = ({ element }) => element;

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default wrapRootElement;
