/**
 * Formats a given date string into `M YYYY` format.
 *
 * @example
 * ```
 * formatDate('01-05-2020');
 * // Jan 2020
 * ```
 *
 * @param {string} dateString a date string acceptable via the `Date` class
 *
 * @returns {string} the formatted date string
 */
const formatDate = dateString => {
  if (!dateString) {
    return 'Present';
  }
  const date = new Date(dateString);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

export default formatDate;
