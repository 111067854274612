/**
 * Helper for quickly attaching event listeners to a target. Returns
 * the `off` method to remove the listener.
 *
 * @param {Element} target the target to attach the listener to
 * @param {string} event the event to listen to
 * @param {(evt: Event) => void} listener the callback function when the
 * event is triggered
 *
 * @returns {function} an `off` method to remove the listener
 */
const on = (target, event, listener) => {
  if (target) {
    target.addEventListener(event, listener);
  }

  return () => {
    if (target) {
      target.removeEventListener(event, listener);
    }
  };
};

export default on;
