import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

export const Arrow = () => (
  <span className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className={sharedStyles.fill} d="M2 6H4V12H18V10H20V12H22V14H20V16H18V14H2V6Z" />
      <path className={sharedStyles.fill} d="M15.9985 7.99994H17.9985V9.99994H15.9985V7.99994Z" />
      <path className={sharedStyles.fill} d="M17.9985 18H15.9985V16H17.9985V18Z" />
    </svg>
  </span>
);

export default Arrow;
