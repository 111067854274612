import React, { Fragment, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useState, useMultiRef } from '@upstatement/react-hooks';
import { Link } from '@src/components';
import { Close, Menu } from '@src/svgs';
import { className, SanityLink, on } from '@src/utils';
import styles from './nav.module.scss';
import { KEY_CODES } from '@src/utils/constants';

const NAV_QUERY = graphql`
  query NavQuery {
    menu: sanityMenu {
      links {
        ...SanityLink
      }
    }
  }
`;

const Nav = () => {
  const isSSR = typeof window === 'undefined';

  const { menu } = useStaticQuery(NAV_QUERY);

  const navRef = useRef(null);
  const logoLinkRef = useRef(null);
  const menuButtonRef = useRef(null);
  const [mobileLinks, setMobileLink] = useMultiRef();

  const [navHeight, setNavHeight] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /**
   * Toggles the mobile menu open state.
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  /**
   * Force closes the mobile menu.
   */
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  /**
   * Recalculates and updates the nav height when the window resizes.
   */
  const onResize = () => {
    let navHeight = 0;
    if (navRef.current) {
      navHeight = navRef.current.clientHeight;
    }
    setNavHeight(navHeight);
  };

  /**
   * Event handler for the tab key. Creates a simulated tab "loop", preventing any items
   * not in the nav or mobile menu from being focused.
   *
   * @param {KeyboardEvent} evt the keydown event for the `tab` key
   */
  const handleTab = evt => {
    const links = [logoLinkRef.current, menuButtonRef.current, ...mobileLinks.current];

    const activeElement = document.activeElement;
    const first = links[0];
    const last = links[links.length - 1];

    // If no element is selected or the active element is not focusable, go to first link
    if (!activeElement || !links.includes(activeElement)) {
      evt.preventDefault();
      first.focus();
      return;
    }

    // models the tab loop
    const tabLoop = [
      {
        // last link => nav logo
        from: last,
        to: first,
        shiftKey: false,
      },
      {
        // nav logo => last link (shift+tab)
        from: first,
        to: last,
        shiftKey: true,
      },
    ];

    const loopFound = tabLoop.find(({ from, to, shiftKey }) => {
      // if loop condition met, go from -> to
      if (shiftKey === evt.shiftKey && activeElement === from) {
        evt.preventDefault();
        to.focus();
        // stop loop
        return true;
      }
    });

    // If a loop condition was found, break out
    if (loopFound) {
      return;
    }

    // if no conditions above are met, continue with native tab implementation
  };

  /**
   * Event handler for key down. Closes the mobile menu when the escape key is clicked,
   * or simulates the tab loop when tab is used.
   *
   * @param {KeyboardEvent} evt the keydown event
   */
  const onKeyDown = evt => {
    switch (evt.keyCode) {
      case KEY_CODES.escape: {
        closeMenu();
        break;
      }
      case KEY_CODES.tab: {
        handleTab(evt);
        break;
      }
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      const off = on(window, 'keydown', onKeyDown);
      return off;
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (!isSSR) {
      onResize();
      const off = on(window, 'resize', onResize);
      return off;
    }
  }, []);

  const links = menu.links.reduce((acc, sanityLink) => {
    const link = SanityLink.resolve(sanityLink);
    if (link.to) {
      acc.push(link);
    }
    return acc;
  }, []);

  return (
    <Fragment>
      <nav ref={navRef} className={styles.nav}>
        <Link className={styles.skipToContent} to="#content">
          Skip to content
        </Link>

        <Link ref={logoLinkRef} className={styles.logoLink} to="/" onClick={closeMenu}>
          Ray Namar
        </Link>

        <ul className={styles.linkList}>
          {links.map(link => (
            <li key={link.key} className={styles.linkItem}>
              <Link className={styles.link} to={link.to}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>

        <button ref={menuButtonRef} className={styles.menuButton} onClick={toggleMenu}>
          <div className={styles.menuIcon}>{isMenuOpen ? <Close /> : <Menu />}</div>
        </button>
      </nav>

      <div
        {...className(styles.mobileMenu, !isMenuOpen && styles.hidden)}
        style={{ '--nav-offset': `${navHeight}px` }}>
        <ul className={styles.mobileLinkList}>
          {links.map((link, i) => (
            <li key={link.key} className={styles.mobileLinkItem}>
              <Link
                ref={setMobileLink(i)}
                className={styles.mobileLink}
                to={link.to}
                onClick={closeMenu}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

Nav.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Nav;
