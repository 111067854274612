import React, { useEffect, lazy, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap, { SteppedEase } from 'gsap';
import { useState, useMap } from '@upstatement/react-hooks';
import { Suspense } from '@src/components';
import { HuhBlock } from '@src/svgs';
import { nextTick } from '@src/utils';
import Coin from './Coin';
import styles from './easterEgg.module.scss';

const LOAD_AT_CLICKS = 40;
const REQUIRED_CLICKS = 50;

const EasterEgg = ({ onClick: customOnClick }) => {
  const blockRef = useRef(null);

  const [Modal, setModal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const coinMap = useMap();
  const [clicks, setClicks] = useState(0);

  /**
   * Bounces the huh block on click, to simulate Mario bumping his
   * head into it.
   */
  const bounceBlock = () => {
    gsap.fromTo(
      blockRef.current,
      { y: 0 },
      {
        duration: 0.05,
        ease: SteppedEase.config(1),
        y: -3,
        yoyo: true,
        repeat: 1,
      },
    );
  };

  /**
   * Adds a coin to the map at the given index. When the coin unmounts, it
   * will delete itself from the map.
   *
   * @param {number} index the index at which to add the coin to the map
   */
  const addCoin = index => {
    const onCoinUnmount = () => {
      coinMap.delete(index);
    };

    coinMap.set(index, <Coin key={clicks} onUnmount={onCoinUnmount} />);
  };

  /**
   * Event handler for when the block is clicked. Perform the bounce animation,
   * update the current number of clicks, spawn a new coin, and perform any
   * click actions from the parent.
   */
  const onClick = () => {
    bounceBlock();
    addCoin(clicks);
    setClicks(clicks => clicks + 1);
    if (customOnClick) {
      customOnClick();
    }
  };

  /**
   * Event handler for when the modal is closed. Refocuses the huh block button
   * to be clicked again.
   */
  const onModalClose = () => {
    setIsModalOpen(false);
    nextTick(() => {
      blockRef.current.focus();
    });
  };

  useEffect(() => {
    if (!Modal && clicks >= LOAD_AT_CLICKS) {
      setModal(lazy(() => import('./Modal')));
    } else if (clicks >= REQUIRED_CLICKS) {
      // TODO: re-enable easter egg once modal is complete
      // setIsModalOpen(true);
      setClicks(0);
    }

    // if (clicks > 0 && clicks < REQUIRED_CLICKS && clicks % 10 === 0) {
    //   // TODO: add announcements
    //   console.log(clicks);
    // }
  }, [clicks]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        {Modal && (
          <Suspense fallback={null}>
            <Modal onClose={onModalClose} show={isModalOpen} />
          </Suspense>
        )}
        <div className={styles.blockContainer}>
          <button
            ref={blockRef}
            className={styles.block}
            disabled={isModalOpen}
            aria-label="Click this block"
            onClick={onClick}>
            <div className={styles.blockSvg}>
              <HuhBlock />
            </div>
          </button>
          <div className={styles.coins}>{Array.from(coinMap.values())}</div>
        </div>
      </div>
    </div>
  );
};

EasterEgg.propTypes = {
  onClick: PropTypes.func,
};

export default EasterEgg;
