import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Pause = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <rect className={sharedStyles.fill} x="5" width="15" height="50" />
      <rect className={sharedStyles.fill} x="30" width="15" height="50" />
    </svg>
  </div>
);

export default Pause;
