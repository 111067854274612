import React from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import LinkMark from './marks/LinkMark';
import { className } from '@src/utils';
import styles from './richText.module.scss';

const serializers = {
  marks: {
    link: LinkMark,
  },
};

const RichText = ({ className: customClassName, content }) => (
  <BlockContent
    {...className(styles.content, customClassName)}
    blocks={content}
    serializers={serializers}
    renderContainerOnSingleChild
  />
);

RichText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
};

export default RichText;
