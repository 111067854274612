import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const HuhBlock = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect className={sharedStyles.fill} x="2" y="2" width="1" height="1" />
      <rect className={sharedStyles.fill} x="13" y="2" width="1" height="1" />
      <rect className={sharedStyles.fill} x="13" y="13" width="1" height="1" />
      <rect className={sharedStyles.fill} x="2" y="13" width="1" height="1" />
      <rect className={sharedStyles.fill} x="7" y="11" width="2" height="2" />
      <path className={sharedStyles.fill} d="M5 3H10V4H11V8H9V10H7V8H8V7H9V4H6V7H4V4H5V3Z" />
      <path
        vectorEffect="non-scaling-stroke"
        className={sharedStyles.stroke}
        d="M0.5 0.5V15.5H15.5V0.5H0.5Z"
      />
    </svg>
  </div>
);

export default HuhBlock;
