/**
 * Calls the given function on the next tick.
 *
 * @param {function} callback the function to call next tick
 */
const nextTick = callback => {
  setTimeout(callback, 1);
};

export default nextTick;
