import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

export const Coin = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect fill="black" x="4" y="1" width="8" height="14" />
      <rect fill="black" x="3" y="4" width="10" height="8" />
      <rect className={sharedStyles.fill} x="7" y="12" width="2" height="1" />
      <rect className={sharedStyles.fill} x="3" y="2" width="1" height="2" />
      <rect className={sharedStyles.fill} x="3" y="12" width="1" height="2" />
      <rect className={sharedStyles.fill} x="9" y="4" width="1" height="8" />
      <rect className={sharedStyles.fill} x="7" y="3" width="2" height="1" />
      <rect className={sharedStyles.fill} x="6" y="4" width="1" height="8" />
      <rect className={sharedStyles.fill} x="12" y="2" width="1" height="2" />
      <rect className={sharedStyles.fill} x="12" y="12" width="1" height="2" />
      <rect className={sharedStyles.fill} x="13" y="4" width="1" height="8" />
      <rect className={sharedStyles.fill} x="2" y="4" width="1" height="8" />
      <rect className={sharedStyles.fill} x="4" y="1" width="2" height="1" />
      <rect className={sharedStyles.fill} x="4" y="14" width="2" height="1" />
      <rect className={sharedStyles.fill} x="10" y="1" width="2" height="1" />
      <rect className={sharedStyles.fill} x="10" y="14" width="2" height="1" />
      <rect className={sharedStyles.fill} x="6" width="4" height="1" />
      <rect className={sharedStyles.fill} x="6" y="15" width="4" height="1" />
    </svg>
  </div>
);

export default Coin;
