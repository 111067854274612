import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Play = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        className={sharedStyles.fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H13V5H21V10H29V15H37V20H45V30H37V35H29V40H21V45H13V50H5V0Z"
      />
    </svg>
  </div>
);

export default Play;
