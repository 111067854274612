import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const LinkedIn = () => (
  <div className={sharedStyles.container}>
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={sharedStyles.fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0H2V1H1V2H0V20H1V21H2V22H20V21H21V20H22V2H21V1H20V0ZM4 4H7V7H4V4ZM4 9H7V18H4V9ZM16 9H12V10H11V9H9V18H12V11H14V12H15V18H18V11H17V10H16V9Z"
      />
    </svg>
  </div>
);

export default LinkedIn;
