import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { className } from '@src/utils';
import styles from './image.module.scss';

const Image = ({ className: customClassName, value }) => {
  if (!value || !value.asset || !value.asset.fluid) {
    return null;
  }
  return (
    <Img
      {...className(styles.image, customClassName)}
      placeholderClassName={styles.imagePlaceholder}
      fluid={value.asset.fluid}
      alt={value.altText}
      durationFadeIn={150}
    />
  );
};

Image.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    altText: PropTypes.string,
    asset: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }),
};

export default Image;
