import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link } from '@src/components';
import { Arrow } from '@src/svgs';
import styles from './gameTease.module.scss';

const GameTease = ({ game: { slug, summary, title } }) => (
  <Link className={styles.link} to={`/games/${slug.current}`}>
    <p className={styles.title}>{title}</p>
    <p className={styles.summary}>{summary}</p>
    <div className={styles.arrow}>
      <Arrow />
    </div>
  </Link>
);

GameTease.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameTease;

export const query = graphql`
  fragment GameTease on SanityGame {
    _id
    title
    slug {
      current
    }
    timeline {
      from
      to
    }
    summary
    preview {
      altText
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
`;
