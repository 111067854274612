import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import gsap, { SteppedEase } from 'gsap';
import { useState } from '@upstatement/react-hooks';
import { Coin as CoinSvg } from '@src/svgs';
import styles from './coin.module.scss';

const ENTER_DURATION = 250;
const WAIT_DURATION = 150;
const EXIT_DURATION = 200;

const Coin = ({ onUnmount }) => {
  const [mounted, setMounted] = useState(false);

  /**
   * On enter, step the coin up to the main position.
   *
   * @param {HTMLDivElement} node the transition's main element node
   */
  const onEnter = node => {
    gsap.fromTo(
      node,
      {
        rotateY: 210,
        y: 0,
      },
      {
        duration: ENTER_DURATION / 1000,
        ease: SteppedEase.config(6),
        rotateY: 0,
        y: node.clientHeight * -1.75,
      },
    );
  };

  /**
   * When the coin has finished entering, wait a short duration
   * before triggering the unmount.
   */
  const onEntered = () => {
    setTimeout(() => {
      setMounted(false);
    }, WAIT_DURATION);
  };

  /**
   * On exit, smoothly transition the coin up and lower its opacity to 0.
   *
   * @param {HTMLDivElement} node the transition's main element node
   */
  const onExit = node => {
    gsap.to(node, {
      duration: EXIT_DURATION / 1000,
      ease: SteppedEase.config(4),
      opacity: 0,
      rotateY: -90,
      y: node.clientHeight * -1,
    });
  };

  const onExited = () => {
    if (onUnmount) {
      onUnmount();
    }
  };

  useEffect(() => {
    // Trigger transition on mount
    setMounted(true);
  }, []);

  return (
    <Transition
      in={mounted}
      timeout={{ enter: ENTER_DURATION, exit: EXIT_DURATION }}
      onEnter={onEnter}
      onEntered={onEntered}
      onExit={onExit}
      onExited={onExited}
      unmountOnExit>
      <div className={styles.container}>
        <CoinSvg />
      </div>
    </Transition>
  );
};

Coin.propTypes = {
  onUnmount: PropTypes.func,
};

export default Coin;
