import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@src/components';
import { SanityLink } from '@src/utils';
import styles from './linkMark.module.scss';

const LinkMark = ({ children, mark }) => {
  const link = new SanityLink(mark);
  return (
    <Link className={styles.link} to={link.to}>
      {children}
    </Link>
  );
};

LinkMark.propTypes = {
  children: PropTypes.node.isRequired,
  mark: PropTypes.shape({
    _key: PropTypes.string.isRequired,
    isInternal: PropTypes.bool,
    internalLink: PropTypes.object,
    externalLink: PropTypes.string,
  }),
};

export default LinkMark;
