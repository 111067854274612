import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const Link = forwardRef(
  (
    { activeClassName, children, className, label, labelledBy, onClick, tabIndex, title, to },
    ref,
  ) => {
    const isInternal = /^\/(?!\/)/.test(to);
    const isFile = /\.[0-9a-z]+$/i.test(to);
    const isInternalLink = isInternal && !isFile;

    const props = {
      className,
      onClick,
    };

    if (tabIndex !== 0) {
      props.tabIndex = tabIndex;
    }

    if (label) {
      props['aria-label'] = label;
    }

    if (labelledBy) {
      props['aria-labelledby'] = labelledBy;
    }

    if (title) {
      props.title = title;
    }

    if (isInternalLink) {
      return (
        <GatsbyLink ref={ref} to={to} activeClassName={activeClassName} {...props}>
          {children}
        </GatsbyLink>
      );
    }

    // Protect against anchors opening in new tab
    if (!to.startsWith('#')) {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }

    return (
      <a ref={ref} href={to} {...props}>
        {children}
      </a>
    );
  },
);

Link.displayName = 'Link';

Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  labelledBy: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  tabIndex: 0,
};

export default Link;
