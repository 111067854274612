import React, { useRef } from 'react';
import { useState } from '@upstatement/react-hooks';
import { EasterEgg, SocialLink } from '@src/components';
import { className } from '@src/utils';
import { GitHub, LinkedIn, Twitter } from '@src/svgs';
import styles from './footer.module.scss';
import { useStaticQuery, graphql } from 'gatsby';

const FOOTER_QUERY = graphql`
  query FooterQuery {
    footer: sanityFooter {
      copyright
      socialLinks {
        _key
        label
        link
        icon
      }
    }
  }
`;

const COPY_HIDE_DURATION = 550;

const ICON_MAP = {
  twitter: Twitter,
  github: GitHub,
  linkedin: LinkedIn,
};

const Footer = () => {
  const { footer } = useStaticQuery(FOOTER_QUERY);

  const copyHideTimeout = useRef(null);

  const [isCopyHidden, setIsCopyHidden] = useState(false);

  /**
   * Cleaes the current timeout for the copyright hidden state.
   *
   * @returns {boolean} whether there was a timeout cleared
   */
  const clearCopyHideTimeout = () => {
    if (!copyHideTimeout.current) {
      return false;
    }
    clearTimeout(copyHideTimeout.current);
    copyHideTimeout.current = null;
    return true;
  };

  /**
   * Event handler for the easter egg click. Hides the copyright text, and
   * sets a timeout to reshow it after 2 seconds.
   */
  const onEggClick = () => {
    if (!isCopyHidden) {
      setIsCopyHidden(true);
    }

    clearCopyHideTimeout();
    copyHideTimeout.current = setTimeout(() => {
      setIsCopyHidden(false);
    }, COPY_HIDE_DURATION);
  };

  return (
    <footer className={styles.container}>
      <div className={styles.inner}>
        <ul className={styles.socialLinks}>
          {footer.socialLinks.map(({ _key, label, link, icon }) => {
            const Icon = ICON_MAP[icon];
            if (!Icon) {
              return null;
            }
            return (
              <li key={_key} className={styles.socialLinkItem}>
                <SocialLink to={link} label={label} icon={Icon} />
              </li>
            );
          })}
        </ul>
        <p {...className(styles.copyright, isCopyHidden && styles.hidden)}>{footer.copyright}</p>
      </div>
      <EasterEgg onClick={onEggClick} />
    </footer>
  );
};

export default Footer;
