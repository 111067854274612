import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const GitHub = () => (
  <div className={sharedStyles.container}>
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={sharedStyles.fill}
        d="M7 0H15V1H17V2H18V3H19V4H20V5H21V7H22V15H21V17H20V18H19V19H18V20H17V21H15V22H14V16H13V15H15V14H16V13H17V8H16V5H14V6H13V7H9V6H8V5H6V8H5V13H6V14H7V15H9V16H7V17H6V16H5V15H4V16H5V17H6V18H9V22H7V21H5V20H4V19H3V18H2V17H1V15H0V7H1V5H2V4H3V3H4V2H5V1H7V0Z"
      />
    </svg>
  </div>
);

export default GitHub;
