import React, { Suspense as NativeSuspense } from 'react';
import PropTypes from 'prop-types';

const Suspense = ({ children }) => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return null;
  }

  return <NativeSuspense fallback={null}>{children}</NativeSuspense>;
};

Suspense.propTypes = {
  children: PropTypes.node,
};

export default Suspense;
