import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Twitter = () => (
  <div className={sharedStyles.container}>
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={sharedStyles.fill}
        d="M13 2V3H12V4H11V7H9V6H7V5H5V4H4V3H3V6H4V7H1V8H2V9H4V10H2V11H3V12H4V13H5V14H8V15H7V16H6V17H4V18H2V17H1V18H2V19H4V20H9V19H12V18H14V17H15V16H16V15H17V13H18V10H19V7H21V6H19V5H20V4H21V3H20V4H18V3H16V2H13Z"
      />
    </svg>
  </div>
);

export default Twitter;
