import { graphql } from 'gatsby';

/** @typedef {{ __typename: string, title?: string, slug?: { current: string }, internal?: { type: string } }} SanityInternalLink */
/** @typedef {{ text?: string, isInternal?: boolean, internalLink?: SanityInternalLink, externalLink?: string }} SanityLinkObject */

/**
 * A Gatsby-compatible link object from Sanity.
 */
class SanityLink {
  /**
   * Creates a new link object.
   *
   * @param {SanityLinkObject | undefined} sanityLink a link from a Sanity GraphQL query
   */
  constructor(sanityLink) {
    this._link = sanityLink;
    this.key = '';
    this.text = '';
    this.to = '';

    if (sanityLink) {
      this.key = sanityLink._key;
      this.text = sanityLink.text;
      this._resolveLink(sanityLink);
    }
  }

  /**
   * Resolves a Sanity link object, either grabbing the fields from the internal
   * or external link depending on its `isInternal` flag.
   *
   * @param {SanityLinkObject} sanityLink a link from a Sanity GraphQL query
   */
  _resolveLink(sanityLink) {
    const { isInternal, internalLink, externalLink } = sanityLink;
    if (isInternal && internalLink) {
      this._resolveInternalLink(internalLink);
    } else if (!isInternal && externalLink) {
      this._resolveExternalLink(externalLink);
    }
  }

  /**
   * Resolves the text and to fields for an internal link.
   *
   * @param {SanityInternalLink} internalLink the defined internal link references
   */
  _resolveInternalLink(internalLink) {
    this.text = this.text || internalLink.title;

    const sanityType =
      (internalLink.internal && internalLink.internal.type) || internalLink.__typename;
    switch (sanityType) {
      case 'SanityHomePage': {
        this.to = '/';
        break;
      }
      case 'SanityAboutPage': {
        this.to = '/about';
        break;
      }
      case 'SanityGamesPage': {
        this.to = '/games';
        break;
      }
      case 'SanityBasicPage': {
        this.to = `/${internalLink.slug.current}`;
        break;
      }
      case 'SanityDoc': {
        const { asset, name } = internalLink.file;
        let fileName = asset.originalFilename;
        if (name) {
          fileName = `${name}.${asset.extension}`;
        }
        this.to = `/files/${fileName}`;
        break;
      }
      case 'SanityGame': {
        this.to = `/games/${internalLink.slug.current}`;
        break;
      }
    }
  }

  /**
   * Resolves the text and to fields for an external link.
   *
   * @param {string} externalLink the defined external link URL
   */
  _resolveExternalLink(externalLink) {
    this.text = this.text || externalLink;
    this.to = externalLink;
  }

  /**
   * Helper for quickly creating Sanity links.
   *
   * @param {SanityLinkObject | undefined} sanityLink a link from a Sanity GraphQL query
   *
   * @returns {SanityLink} the resolved SanityLink object
   */
  static resolve(sanityLink) {
    return new SanityLink(sanityLink);
  }
}

export default SanityLink;

// Defines a `SanityLink` fragment that can be used across queries
export const query = graphql`
  fragment SanityLink on SanityLink {
    _key
    text
    isInternal
    internalLink {
      __typename
      ... on SanityHomePage {
        title
      }
      ... on SanityAboutPage {
        title
      }
      ... on SanityGamesPage {
        title
      }
      ... on SanityBasicPage {
        title
        slug {
          current
        }
      }
      ... on SanityDoc {
        file {
          asset {
            extension
            originalFilename
          }
          name
        }
      }
      ... on SanityGame {
        title
        slug {
          current
        }
      }
    }
    externalLink
  }
`;
