import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

export const StraightArrow = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        className={sharedStyles.fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3H12V5H10V3ZM12 11H14V9H16V7H14V5H12V7H0V9H12V11ZM12 11V13H10V11H12Z"
      />
    </svg>
  </div>
);

export default StraightArrow;
