import React from 'react';
import PropTypes from 'prop-types';
import { Footer, Nav, SEO } from '@src/components';
import styles from './layout.module.scss';

const Layout = ({ children, location }) => (
  <div className={styles.container}>
    <SEO />
    <Nav location={location} />
    <main id="content" className={styles.main}>
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
