import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@src/components';
import styles from './socialLink.module.scss';

const SocialLink = ({ label, icon: Icon, to }) => (
  <Link className={styles.link} to={to} title={label} label={label}>
    <div className={styles.icon}>
      <Icon />
    </div>
  </Link>
);

SocialLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};

export default SocialLink;
