import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const SITE_SEO_QUERY = graphql`
  query SiteSeoQuery {
    settings: sanitySiteSettings {
      siteName
      twitterHandle
    }
  }
`;

const SEO = ({ title, seo, description }) => {
  const { settings } = useStaticQuery(SITE_SEO_QUERY);

  const formatTitle = (localTitle, globalTitle) => {
    if (!localTitle && !globalTitle) {
      return '';
    } else if (!localTitle) {
      return globalTitle;
    } else if (!globalTitle || localTitle === globalTitle) {
      return localTitle;
    }
    return `${localTitle} | ${globalTitle}`;
  };

  const metaTitle = formatTitle((seo && seo.title) || title, settings.siteName);

  const metaDescription = (seo && seo.description) || description;

  const metaImage = seo && seo.image;
  const metaImageUrl = metaImage && metaImage.asset && metaImage.asset.url;
  const metaImageAlt = metaImage && metaImage.altText;

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        /* OG Meta */
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:site_name',
          content: settings.siteName,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        /* Twitter Meta */
        {
          property: 'twitter:card',
          content: 'summary_large_image', // TODO: add option to switch in Sanity
        },
        {
          property: 'twitter:creator',
          content: settings.twitterHandle,
        },
        {
          property: 'twitter:site',
          content: settings.twitterHandle,
        },
        /* OG and Twitter meta */
        ...['og', 'twitter'].flatMap(type => {
          const meta = [
            {
              property: `${type}:description`,
              content: metaDescription,
            },
            {
              property: `${type}:title`,
              content: metaTitle,
            },
            // TODO: add URL
            // {
            //   property: `${type}:url`,
            //   content: metaUrl,
            // },
          ];

          if (metaImageUrl) {
            meta.push({
              property: `${type}:image`,
              content: metaImageUrl,
            });

            if (metaImageAlt) {
              meta.push({
                property: `${type}:image:alt`,
                content: metaImageAlt,
              });
            }
          }

          return meta;
        }),
      ]}
    />
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      altText: PropTypes.string,
      asset: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  }),
};

SEO.defaultProps = {
  description: '',
  title: '',
};

export default SEO;

export const query = graphql`
  fragment SanitySeo on SanitySeo {
    title
    description
    image {
      altText
      asset {
        url
      }
    }
  }
`;
