import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Menu = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect className={sharedStyles.fill} y="1" width="16" height="2" />
      <rect className={sharedStyles.fill} y="7" width="16" height="2" />
      <rect className={sharedStyles.fill} y="13" width="16" height="2" />
    </svg>
  </div>
);

export default Menu;
