import React from 'react';
import sharedStyles from '@src/svgs/sharedStyles.module.scss';

const Gravestone = () => (
  <div className={sharedStyles.container}>
    <svg className={sharedStyles.svg} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path className={sharedStyles.fill} d="M22 0H42V1H40V2H36V1H22V0Z" />
      <path className={sharedStyles.fill} d="M18 2H22V1H18V2Z" />
      <path className={sharedStyles.fill} d="M15 3H18V2H15V3Z" />
      <path className={sharedStyles.fill} d="M14 4H15V3H14V4Z" />
      <path className={sharedStyles.fill} d="M13 5H14V4H13V5Z" />
      <path className={sharedStyles.fill} d="M12 6H13V5H12V6Z" />
      <path className={sharedStyles.fill} d="M11 8H12V6H11V8Z" />
      <path className={sharedStyles.fill} d="M10 12H11V8H10V12Z" />
      <path className={sharedStyles.fill} d="M13 51V50H10V12H9V51H5V50H3V51H5V52H9V51H13Z" />
      <path className={sharedStyles.fill} d="M17 51H13V52H17V51Z" />
      <path className={sharedStyles.fill} d="M21 51V50H17V51H21Z" />
      <path className={sharedStyles.fill} d="M25 51H21V52H25V51Z" />
      <path className={sharedStyles.fill} d="M29 51V50H25V51H29Z" />
      <path className={sharedStyles.fill} d="M33 51H29V52H33V51Z" />
      <path
        className={sharedStyles.fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 48H34V50H33V51H37V52H41V51H45V52H49V51H53V52H57V51H61V50H57V51H55V12H54V8H53V6H52V5H51V4H50V3H49V2H46V1H42V2H40V3H43V4H44V5H45V6H46V8H47V12H48V51H45V50H41V51H37V49H38V46H37V45H36V46H35V48ZM35 48H36V46H37V49H36V50H35V48ZM48 12V8H47V6H46V5H45V4H44V3H43V2H46V3H49V4H50V5H51V6H52V8H53V12H54V51H53V50H49V12H48Z"
      />
      <rect className={sharedStyles.fill} width="1" height="1" transform="matrix(-1 0 0 1 41 55)" />
      <rect className={sharedStyles.fill} width="1" height="1" transform="matrix(-1 0 0 1 22 63)" />
      <rect className={sharedStyles.fill} width="1" height="1" transform="matrix(-1 0 0 1 8 59)" />
      <path className={sharedStyles.fill} d="M56 59H55V60H56V59Z" />
      <path className={sharedStyles.fill} d="M56 57H55V58H56V57Z" />
      <path className={sharedStyles.fill} d="M55 58H54V59H55V58Z" />
      <path className={sharedStyles.fill} d="M19 56H18V57H19V56Z" />
      <path className={sharedStyles.fill} d="M20 55H19V56H20V55Z" />
      <path className={sharedStyles.fill} d="M21 56H20V57H21V56Z" />
      <path className={sharedStyles.fill} d="M20 57H19V58H20V57Z" />
      <path
        className={sharedStyles.fill}
        d="M42 15H41V16H40V17H39V18H38V19H37V20H36V21H35V23H40V26H39V27H43V26H42V23H43V22H42V18H41V19H40V22H36V21H37V20H38V19H39V18H40V17H41V16H42V15Z"
      />
      <path
        className={sharedStyles.fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 15V16H31V17H32V19H33V23H32V25H31V26H30V27H26V26H25V25H24V23H23V19H24V17H25V16H26V15H30ZM29 26V25H30V23H31V19H30V17H29V16H27V17H26V19H25V23H26V25H27V26H29Z"
      />
      <path
        className={sharedStyles.fill}
        d="M20 15H19V16H18V17H17V18H16V19H15V20H14V21H13V23H18V26H17V27H21V26H20V23H21V22H20V18H19V19H18V22H14V21H15V20H16V19H17V18H18V17H19V16H20V15Z"
      />
    </svg>
  </div>
);

export default Gravestone;
